import { Transition } from '@headlessui/react';
import { type AnyObject } from '@Types';
import clsx from 'clsx';
import { Icon } from 'FrontRoyalIcon';
import { type ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { Typography } from 'Typography';

type Dismissable = {
    disableDismiss?: false;
    onDismiss: () => void;
};

type NonDismissable = {
    disableDismiss: true;
    onDismiss?: () => void;
};

type MaybeDismissable = Dismissable | NonDismissable;

type Props = {
    show: boolean;
    onAfterLeave?: () => void;
    children: ReactNode;
    cta?: {
        text: { headline: { key: string; params?: AnyObject }; subtext?: { key: string; params?: AnyObject } };
        buttonText: { key: string; params?: AnyObject };
        onClick: () => void;
    };
    className?: string;
} & MaybeDismissable;

export function DismissableMessage({ onDismiss, disableDismiss, show, onAfterLeave, children, cta, className }: Props) {
    const { t } = useTranslation('back_royal');

    return (
        <Transition
            show={show}
            appear
            enter="transition duration-300 delay-500 ease-out"
            enterFrom="opacity-0 translate-y-2"
            enterTo="opacity-100 translate-y-0"
            leave="transition duration-150 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={onAfterLeave}
        >
            <div
                data-testid="dismissable-message"
                className={clsx(
                    'relative flex max-h-full w-full max-w-[396px] flex-col rounded-md border border-white/40 bg-map-blue-dark/90  text-white',
                    className,
                )}
            >
                <div className="px-3.5 py-8 md:px-8">
                    {!disableDismiss && (
                        <button
                            data-testid="dismiss-message-button"
                            type="button"
                            className="absolute right-0 top-0 rounded-md p-2"
                            onClick={onDismiss}
                        >
                            <span className="sr-only">{t('dismissable_message.close_message')}</span>
                            <Icon iconName="Close24" aria-hidden="true" className="text-white/50" />
                        </button>
                    )}
                    <div className="max-h-full overflow-y-auto">{children}</div>
                </div>
                {!!cta && (
                    <div className="rounded-b-md bg-map-blue-darker px-3.5 py-5 md:px-8">
                        <div className="flex h-fit max-h-full items-center justify-between">
                            <div className="">
                                <Typography variant="body4" className="text-xxs uppercase leading-[15px]">
                                    {t(cta.text.headline.key, cta.text.headline.params ?? {})}
                                </Typography>
                                {cta.text.subtext && (
                                    <Typography variant="body4" className="text-sm font-semibold leading-[15px]">
                                        {t(cta.text.subtext.key, cta.text.subtext.params ?? {})}
                                    </Typography>
                                )}
                            </div>
                            <div className="">
                                <button
                                    className="min-w-[100px] rounded-[20px] bg-white px-[7px] py-[11.5px] text-base leading-none text-[#58606D]"
                                    type="button"
                                    onClick={() => cta.onClick()}
                                >
                                    {t(cta.buttonText.key, cta.buttonText.params ?? {})}
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Transition>
    );
}
